@import "./_colors.scss";

.thank-you-page {
  padding-top: 346px;
  padding-bottom: 202px;
  background-position: center, center;
  background-repeat: no-repeat;

  &.valorant {
    background-image: url("../assets/img/valorant-watermark.png"), $heading-bg;
  }

  &.lol {
    background-image: url("../assets/img/lol-watermark.png"), $heading-bg;
  }

  &.wr {
    background-image: url("../assets/img/wr-watermark.png"), $heading-bg;
  }

  hr {
    color: $primary-red;
    margin: 0;
    height: 2px;
    opacity: 1;
  }

  .heading {
    padding-top: 87px;
    font-family: InterV;
    font-size: 72px;
    font-weight: 800;
    color: $off-white;
    text-align: center;
  }

  .subHeading {
    opacity: 0.6;
    font-family: InterV;
    font-size: 24px;
    line-height: 1.33;
    text-align: center;
    color: $text-gray;
    margin-top: 45px;
    margin-bottom: 74px;
  }

  .text {
    margin-top: 0;
    margin-bottom: 77px;

    opacity: 0.6;
    font-family: InterV;
    font-size: 14px;
    line-height: 1.71;
    text-align: center;
    color: $text-gray;
  }

  .home-page-button {
    margin-top: 81px;
  }
}
