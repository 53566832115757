@import "../_colors.scss";

.faq-page {
  .bg {
    width: 100%;
    background-image: url("../../assets/img/riotgames-fist-watermark.png"),
      $heading-bg;
    background-position: center, center;
    background-repeat: no-repeat;
    background-size: 41% 140%, cover;

    h1 {
      padding-top: 271px;
      padding-bottom: 96px;
      font-family: InterV;
      font-size: 60px;
      font-weight: 800;
      line-height: 1.25;
      text-align: start;
      color: $off-white;
      text-transform: uppercase;
      font-family: "RiotSans";
    }
  }

  .faqs-container {
    min-height: 600px;
    background-color: $off-white;
    .faq {
      padding: 0 0 30px 0;

      h2 {
        margin: 80px 0 30px 0;
        font-family: InterV;
        font-size: 48.5px;
        font-weight: 800;
        line-height: 1.73;
        text-align: left;
        color: $black;
        text-transform: uppercase;
      }

      .faq-list {
        margin-bottom: 50px;
        border-top: 1px solid $border-black;

        .faq-list-ul {
          padding: 0;
          list-style: none;
          a {
            color: $black !important;
          }

          @import "./Item.scss";
        }
      }
    }
  }

  @media (max-width: 991px) {
    .bg {
      h1 {
        font-size: 40px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .bg {
      h1 {
        font-size: 30px;
      }
    }
  }
}
