@import "./_colors.scss";

#footer {
  font-size: 14px;
  background: $footer-bg;
  display: flex;
  align-items: center;
  padding-top: 52px;
  padding-bottom: 40px;

  .container {
    justify-content: space-between;

    .footer-copy-rights,
    .footer-contact {
      font-size: 12px;
      font-weight: normal;
      letter-spacing: -0.3px;
      color: $text-white;
      margin-inline-start: 30px;
      margin-inline-end: 30px;

      .email {
        font-weight: bold;
        color: $primary-red;
        cursor: pointer;
      }

      @media (max-width: 768px) {
        margin-inline: auto;
      }
    }

    .credits {
      a {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.4px;
        color: $primary-red;
      }

      .termsOfUse {
        margin-inline-end: 30px;
      }
    }

    .social {
      a {
        margin-inline-end: 30px;
      }
    }
  }
  .riot-logo {
    width: 99px;
    height: 28px;
  }
}
