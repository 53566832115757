@import "./_colors.scss";

.loginRegisterModal-container {
  .opened-modal {
    display: block !important;
    background: $transparent-black;
  }

  .modal-dialog {
    overflow-y: auto;
    overflow-x: hidden;

    .modal-content {
      border: none;
    }

    .bg {
      padding: 31px 30px 60px 32px;
      border-radius: 5px;
      background-color: $off-white;

      .modal-header {
        border: none;
        padding-top: 0;

        .button-bg {
          width: 50px;
          height: 50px;
          padding-bottom: 5px;
          border-radius: 5px;
          border: none;
          background-color: $primary-red;
        }

        img {
          width: 17px;
          height: 18px;
          object-fit: contain;
          font-family: InterV;
          font-size: 24px;
          font-weight: 800;
          text-align: center;
          color: $off-white;
        }
      }

      h1 {
        margin: 30px 10px 33px 0;
        font-family: InterV;
        font-size: 48px;
        font-weight: 800;
        text-align: start;
        color: $black;
        white-space: pre;
        text-transform: uppercase;

        @media (max-width: 539.8px) {
          font-size: 24px;
        }
      }

      p {
        margin: 25px 11px 50px 25px;
        opacity: 0.8;
        font-family: InterV;
        font-size: 14px;
        line-height: 1.71;
        text-align: start;
        color: rgba(5, 5, 5, 0.8);
      }

      .text {
        border-left: 2px solid $primary-red;
      }

      .modal-footer {
        border: none;
      }
    }
  }
}
