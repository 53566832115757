@import "../_colors.scss";

.home-page {
  .how-it-works {
    .section-title {
      font-size: 48px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .subtitle-container {
      padding-left: 15px;
      border-left: 2px solid $primary-red;
      margin-top: 28px;
      margin-bottom: 75px;

      .heading {
        font-size: 24px;
        font-weight: bold;
        color: $primary-red;
        text-transform: uppercase;
        text-align: left;
      }

      .subheading {
        font-size: 14px;
        color: $transparent-gray;
        margin-bottom: 0;
        text-align: left;
      }
    }

    .HIW-card {
      width: 100%;
      height: 343px;
      border-radius: 5px;
      position: relative;
      transition: all 1s ease-in-out;

      .avatar {
        object-fit: fill;
        width: 100%;
        height: 383px;
        position: relative;
        top: -40px;
      }

      .HIW-index {
        font-size: 300px;
        font-weight: bold;
        position: absolute;
        left: -25px;
        bottom: -111px;
        z-index: 100;
        letter-spacing: -7.5px;
        color: $off-white;
      }

      .HIW-card-text-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        overflow: hidden;
        padding: 28px 28px 28px 140px;

        .HIW-card-text {
          transform: translateY(220px);
          transition: all 0.3s ease-in-out;

          .HIW-card-title {
            font-size: 24px;
            font-weight: bold;
            text-align: end;
            color: $off-white;
            white-space: pre;
            margin-bottom: 31px;
          }

          .HIW-card-description {
            font-size: 14px;
            text-align: end;
            color: $off-white;
          }
        }
      }

      &::before {
        opacity: 0;
        content: "";
        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        .HIW-card-text-container {
          .HIW-card-text {
            transform: translateY(50px);
          }
        }
      }

      &.lol {
        background-image: linear-gradient(to top, #1c1a2a, #0ac8b9);

        &::before {
          background-image: linear-gradient(to top, #1c1a2a, #1c1a2a, #0ac8b9);
        }

        &:hover {
          &::before {
            opacity: 1;
          }
        }

        .avatar {
          object-fit: contain;
        }
      }

      &.valorant {
        background-image: linear-gradient(to top, #1c1a2a, #8b978f);

        &::before {
          background-image: linear-gradient(to top, #1c1a2a, #1c1a2a, #8b978f);
        }

        &:hover {
          &::before {
            opacity: 1;
          }
        }

        .avatar-3 {
          height: 437px;
          top: -95px;
          left: 40px;
        }
      }

      &.wr {
        background-image: linear-gradient(to top, #1c1a2a, #32c8ff);
      }
    }

    @media (min-width: 1200px) {
      .HIW-card {
        &.lol {
          .avatar {
            object-fit: fill;
          }
        }
      }
    }

    @media (max-width: 991.98px) {
      .HIW-card {
        .avatar {
          object-fit: contain;
        }

        &.valorant {
          .avatar-3 {
            object-fit: contain;
          }
        }
      }
    }
  }
}
