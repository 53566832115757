@import "../_colors.scss";

.edit-profile-data {
  .formContainer {
    background-color: $off-white;

    .heading {
      font-family: InterV;
      font-size: 48px;
      font-weight: bold;
      text-align: start;
      color: $black;
      padding-bottom: 28px;
      margin-bottom: 0;
      text-transform: uppercase;

      @media (max-width: 539.8px) {
        font-size: 24px;
      }
    }
  }
}
