@import "./_colors.scss";

.custom-alert {
  top: 170px;
  background-color: $off-white;
  border-radius: 0.25rem;
  border: 1px solid $transparent-black;
  max-width: 90%;
  z-index: 10000000;

  .toast {
    min-width: 300px;
    border: unset;
    border-radius: 0;

    &.success {
      background-color: $success-green;
      text-align: center;
    }

    &.error {
      background-color: $error-red;

      .close-icon {
        color: $off-white;
        cursor: pointer;
      }
    }

    &.success,
    &.error {
      .message {
        font-size: 18px;
        color: $off-white;
        white-space: pre-line;
      }
    }
  }
}
