@import "../_colors.scss";

.tournament-contract-form {
  padding: 20px 40px;
  position: relative;
  background-color: white;
  page-break-after: always;
  max-width: 450px;
  height: 630px;

  .background-image {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 80px;
    height: 80px;
  }

  * {
    font-family: Arial;
    font-size: 10px;
    color: #000000;
  }

  table.bordered,
  .bordered th,
  .bordered td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .bordered td {
    padding: 5px;
  }

  .details-table td {
    padding-top: 15px;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .section-number {
    display: inline-block;
    width: 5%;
  }

  .link {
    color: #215e9e !important;
  }
}
