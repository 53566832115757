@import "./_colors.scss";

.broadcast-terms-page {
  .bg {
    width: 100%;
    background-image: url("../assets/img/riotgames-fist-watermark.png"),
      $heading-bg;
    background-position: center, center;
    background-repeat: no-repeat;
    background-size: 41% 140%, cover;

    h1 {
      padding-top: 271px;
      padding-bottom: 96px;
      font-size: 60px;
      font-weight: 800;
      line-height: 1.25;
      text-align: start;
      color: $off-white;
      text-transform: uppercase;
      font-family: "RiotSans";
    }
  }

  .content-container {
    h2 {
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
    }

    .title {
      font-size: 24px;
      font-weight: bold;
      color: $black;
      margin-bottom: 20px;
      margin: 0;
    }

    .text {
      margin: 0;
      font-size: 14px;
      color: $black;
      text-align: left;
    }

    p {
      margin: 0;
    }

    ol {
      li {
        margin-bottom: 4px !important;

        a {
          color: $black !important;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .bg {
      h1 {
        font-size: 40px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .bg {
      h1 {
        font-size: 30px;
      }
    }
  }
}
