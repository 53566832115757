@import "./_colors.scss";

.checkbox-form-group {
  input {
    width: 25px;
    height: 25px;
    border: solid 1px $light-black;
    background-color: $off-white;

    &:checked {
      background-color: $primary-red;
      border-color: $light-black;
    }

    &:focus {
      box-shadow: none;
      border: solid 1px $light-black;
    }
  }

  label {
    text-align: start;
    font-family: InterV;
    font-size: 14px;
    color: $light-black;
    margin-inline-start: 12px;
  }

  .input-error {
    margin-inline-start: 12px;
    margin-top: 0;
  }

  &.terms-checkbox {
    a {
      font-weight: bold;
    }
  }
}
