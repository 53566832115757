@import "../_colors.scss";

.our-support-page {
  .bg {
    width: 100%;
    background-image: url("../../assets/img/riotgames-fist-watermark.png"),
      $heading-bg;
    background-position: center, center;
    background-repeat: no-repeat;
    background-size: 41% 140%, cover;

    h1 {
      padding-top: 271px;
      font-size: 60px;
      font-weight: 800;
      line-height: 1.25;
      text-align: start;
      color: $off-white;
      text-transform: uppercase;
      font-family: "RiotSans";
    }

    .subtext {
      font-size: 24px;
      text-align: start;
      color: $text-gray;
      padding-bottom: 70px;
      max-width: 630px;
    }
  }

  .our-support-container {
    .section-title {
      margin: 79px 0 28px;
      font-size: 48px;
      font-weight: 800;
      color: $black;
      text-transform: uppercase;
      text-align: left;
    }

    .subtitle-container {
      padding-left: 15px;
      padding-bottom: 15px;
      border-left: 2px solid $primary-red;
      margin-top: 28px;
      margin-bottom: 79px;

      .heading {
        font-size: 24px;
        font-weight: bold;
        color: $primary-red;
        text-transform: uppercase;
        text-align: left;
      }

      .subheading {
        font-size: 14px;
        color: $transparent-gray;
        margin-bottom: 0;
        margin-top: 25px;
        text-align: left;
      }
    }

    .support-options {
      .option {
        font-size: 14px;
        font-weight: bold;
        color: $black;
        text-align: left;
      }

      ::marker {
        color: $primary-red;
      }

      .game-screenshot {
        max-width: 100%;
      }
    }

    .how-it-works {
      margin-bottom: 70px;

      .section-title {
        margin-bottom: 50px;
      }

      .HIW-card {
        margin-bottom: 30px;

        .HIW-card-content {
          display: flex;

          .icon-container {
            width: 136px;
            height: 136px;
            margin-right: 23px;
            background-color: $input-bg;
            border-radius: 50%;
            display: flex;

            img {
              margin: auto;
            }
          }

          .text-details {
            width: calc(100% - 159px);

            .title {
              font-size: 24px;
              font-weight: bold;
              color: $primary-red;
              margin-bottom: 20px;
            }

            .description {
              margin: 0;
              font-size: 14px;
              color: $transparent-gray;
              text-align: left;
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .bg {
      h1 {
        font-size: 40px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .bg {
      h1 {
        font-size: 30px;
      }
    }
  }
}
