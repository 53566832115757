@import "./_colors.scss";

.input-form-group {
  &.top-label-container {
    flex-direction: column;

    .top-label {
      text-align: start;
    }
  }

  label {
    text-align: end;
    font-family: InterV;
    font-size: 14px;
    color: $light-black;
    text-transform: uppercase;
    margin-top: 7px;

    @media (max-width: 991.8px) {
      text-align: start;
    }
  }

  .form-control {
    padding: 15px 22px;
    background-color: $input-bg;
    border: unset;
    border-radius: 2px;
    font-family: InterV;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.35px;
    text-align: left;
    color: $light-black;

    &[aria-invalid="true"] {
      border: 2px solid $error-red;
    }

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      opacity: 1; /* Firefox */
      color: $light-gray;
      font-family: InterV;
      font-weight: normal;
    }

    &:-ms-input-placeholder {
      color: $light-gray;
      font-family: InterV;
      font-weight: normal;
    }

    &::-ms-input-placeholder {
      color: $light-gray;
      font-family: InterV;
      font-weight: normal;
    }

    /*hide number input arrows*/

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &.other-input-container {
    .form-control {
      padding-inline: 2px;
      border-bottom: solid 1px $border-black;

      &[aria-invalid="true"] {
        border-width: 0;
        border-bottom: 2px solid $error-red;
      }
    }
  }

  &.phone-input-form-group {
    .react-tel-input {
      &.applicant-phone-error {
        border: 2px solid $error-red !important;
      }

      .form-control {
        border-width: 0 !important;
        border-radius: 2px !important;
        background-color: $input-bg !important;
        letter-spacing: -0.35px !important;
        padding-left: 60px !important;
        width: 100% !important;
        line-height: 1.5 !important;
        height: auto !important;
      }
      .flag-dropdown {
        border-width: 0;
        border-inline-end-width: 1px !important;

        .selected-flag {
          background-color: $input-bg !important;
          letter-spacing: -0.35px !important;
        }
      }
    }
  }
}

[dir="rtl"] {
  .phone-input-form-group {
    .react-tel-input {
      .form-control {
        padding-left: 10px !important;
        padding-right: 60px !important;
        direction: ltr !important;
      }

      .selected-flag {
        padding: 0 8px 0 0 !important;
      }

      .selected-flag {
        .arrow {
          left: unset !important;
          right: 30px !important;
        }
      }
    }
  }
}
