@import "./_colors.scss";

#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 27px 0 23px;

  .header-container {
    justify-content: space-between;
  }

  &.header-scrolled,
  &.header-inner-pages {
    background: $light-black;
  }

  .logo {
    color: $primary-red;
    letter-spacing: 0px;
    font-size: 36px;
    margin: 0px 0px 0 19px;
    padding: 0;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .logo a {
    color: $primary-red;
    font-family: "InterV";
    font-weight: 700;
  }

  .logo-games-section {
    .select-games-arrow {
      max-width: 30px;
    }

    .game-logo {
      height: 40px;
    }
  }

  .games-list {
    font-size: 16px;
    color: $off-white;
    list-style: none;
    padding-inline-start: 20px;
    margin-top: 5px;
    padding-left: 0;

    li {
      margin-inline-end: 1rem;
      cursor: pointer;
    }

    .selected {
      color: $primary-red;
    }
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0 !important;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
    font-family: "InterV";
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: normal;
  }

  li {
    position: relative;
  }

  a,
  .nav-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $off-white !important;
    white-space: nowrap;
    transition: 0.3s;
    font-weight: normal;
  }

  a i,
  a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 0px;
  }

  .active,
  .active:focus {
    color: $primary-red;
    font-weight: bold;
  }

  a:hover,
  li:hover > a {
    color: $error-red !important;
  }

  .nav-link {
    padding: 0.5rem 0.5rem;

    &.profile-nav-link {
      padding-inline-end: 0;
    }
  }

  .profile-dropdown {
    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-toggle {
      padding-inline-start: 0;

      .dropdown-icon {
        font-size: 23px;
      }
    }

    .dropdown-menu {
      border: none;
      background: transparent;
      top: 80%;
      right: -10px;
      left: unset;

      .dropdown-item {
        color: $off-white;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          background: transparent;
          color: $primary-red;
        }
      }
    }
  }

  .language-ul {
    display: block;
    margin-left: 10px;

    .language {
      cursor: pointer;
      position: relative;
      transition: 0.5s;
      width: 60px;
      margin-inline-start: 10px;
      display: flex;
      align-items: center;

      a {
        font-size: 14px;
      }

      img {
        height: 12px;
        margin-inline-start: 7px;
      }
    }
  }

  /**
  * Mobile Navigation 
  */

  &.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 350px;
    padding-top: 50px;
    background: $gray-ash;
    transition: 0.3s;
    z-index: 999;
    display: flex;
    justify-content: center;

    .mobile-nav-toggle {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    ul {
      display: block;
      padding: 10px 0;
      border-radius: 10px;
      overflow-y: auto;
      transition: 0.3s;
    }

    .language-ul {
      display: flex !important;
      flex-direction: column;
    }
  }

  .mobile-nav-toggle {
    color: $off-white;
    font-size: 28px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    display: none;
  }
  .mobile-nav-toggle.bi-x {
    color: $off-white;
  }

  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
    ul {
      display: none;
    }
  }
}
