@import "../_colors.scss";

.tournaments-calendar {
  .colors-meaning {
    font-size: 14px;
    font-weight: bold;
    color: $black;

    .calendar-home-title {
      h2 {
        font-size: 48px;
        font-weight: bold;
      }
    }

    .duration-color {
      width: 15px;
      height: 15px;
      background-color: $cyan;
    }

    .broadcast-days-color {
      width: 15px;
      height: 15px;
      background-color: $primary-red;
    }
  }

  .tournaments-calendar-container {
    overflow-x: auto;

    .row-border-bottom {
      border-bottom: 1px solid $input-bg;
    }

    .cell-border-left {
      border-left: 1px solid $input-bg;
    }

    .heading {
      .month-selector {
        font-size: 24px;
        font-weight: bold;
        color: $primary-red;
        text-transform: uppercase;
        margin-top: 10px;

        .year {
          color: $black;
        }

        .bi {
          cursor: pointer;
          &::before {
            font-weight: inherit !important;
          }
        }
      }
    }

    .left-sticky-part {
      position: sticky;
      left: 0;
      background-color: $off-white;
    }

    .days-names {
      height: 100%;

      .day {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: $black;
        width: 32px;
        padding: 0;

        &.broadcast-day {
          color: $primary-red;
        }

        &.ar {
          width: 65px;
        }

        &.tournament-day {
          padding-block: 10px;
          min-height: 52px;

          &.tournament-day-container {
            div {
              background: $cyan;
              height: 100%;
            }
          }

          &.broadcast-day-container {
            div {
              background: $primary-red;
              height: 100%;
            }
          }
        }
      }
    }

    .tournament {
      .tournament-details {
        padding-block: 5px;
        padding-inline-end: 10px;

        .tournament-image {
          width: 63px;
          height: 41px;
          object-fit: cover;
          border-radius: 5px;
        }

        .tournament-name-container {
          text-decoration: none;

          .tournament-name {
            text-align: start;
            font-size: 16px;
            font-weight: bold;
            color: $black;
            word-break: break-all;
            cursor: pointer;
          }
        }

        .country-name {
          text-align: start;
          font-size: 12px;
          font-weight: bold;
          color: $primary-red;
        }

        .countries-other {
          font-size: 12px;
          font-weight: bold;
          color: $off-white;
          background-color: $primary-red;
          padding-inline: 5px;
          border-radius: 5px;
          cursor: pointer;
        }

        .format_type {
          text-align: start;
          font-size: 12px;
          color: $black;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .colors-meaning {
      .calendar-home-title {
        h2 {
          font-size: 34px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .colors-meaning {
      .calendar-home-title {
        h2 {
          font-size: 26px;
        }
      }
    }
  }
}
