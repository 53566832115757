@import "./_colors.scss";

.basic-multi-select {
  .select__control {
    background-color: $input-bg !important;
    color: $light-black;
    border-color: $border-black !important;
  }
  .select__menu {
    background-color: $input-bg !important;
    color: $light-black;
  }
  .select__multi-value {
    background-color: hsl(0, 0%, 80%);
  }
}

.un-bordered-select {
  .select__control {
    background-color: transparent !important;
    color: $light-black;
    border-color: $border-black !important;
    border-top-width: 0 !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;

    .select__input {
      width: 90px !important;
    }
  }
  .select__menu {
    background-color: $input-bg !important;
    color: $light-black;
  }
  .select__multi-value {
    background-color: hsl(0, 0%, 80%);
  }

  .select__indicator-separator {
    width: 0 !important;
  }

  .select__indicator {
    &.select__dropdown-indicator {
      svg {
        fill: $primary-red !important;
      }
    }
  }
}
