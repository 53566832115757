@import "../_colors.scss";

.faq-list-item {
  border-bottom: 1px solid $border-black;
  position: relative;

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 18px;
    line-height: inherit;
    position: relative;
    font-family: InterV;
    font-size: 16px;
    font-weight: bold;
    color: $black;
    outline: none;
    cursor: pointer;

    .icon-show,
    .icon-close {
      font-size: 24px;
      color: $primary-red;
      left: inherit;
    }

    .icon-close {
      display: none;
    }

    &.collapsed {
      transition: 0.3s;

      &:hover {
        color: $error-red;
      }

      .icon-close {
        display: inline-block;
      }

      .icon-show {
        display: none;
      }
    }
  }

  p {
    text-align: start;
    margin-bottom: 0;
    padding-bottom: 18px;
    font-family: InterV;
    font-size: 14px;
    color: $gray;
  }
}
