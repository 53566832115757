@import "./_colors.scss";

.countries-tooltip {
  .tooltip-arrow {
    &::before {
      border-right-color: $gray-ash !important;
    }
  }
  .tooltip-inner {
    white-space: pre-line !important;
    text-align: start !important;
    background-color: $gray-ash !important;
  }
}
