@import "../_colors.scss";

.home-page {
  .home-faqs {
    .section-title {
      font-size: 48px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .faq-list {
      margin-bottom: 50px;
      border-top: 1px solid $border-black;

      .faq-list-ul {
        padding: 0;
        list-style: none;
        a {
          color: $black !important;
        }

        @import "../FAQs/Item.scss";
      }
    }
  }
}
