@import "../_colors.scss";

.profile-tournaments {
  .header {
    background-image: $heading-bg;
    padding-top: 271px;
    padding-bottom: 99px;

    .text-container {
      h1 {
        font-size: 72px;
        font-weight: 800;
        text-align: start;
        color: $off-white;
        margin-bottom: 0;
        text-transform: uppercase;
        font-family: "RiotSans";

        @media (max-width: 539.8px) {
          font-size: 40px;
        }
      }

      .nav-tabs {
        border: none;
        margin-top: 50px;

        .nav-link {
          color: $off-white;
          cursor: pointer;
          border-width: 0;
          padding: 0;
          margin-inline-start: 20px;

          &.active {
            background: transparent;
            color: $primary-red;
            border-bottom: 2px solid $primary-red;
          }

          &:hover {
            border-color: transparent;
            outline: none;
            border-bottom: 2px solid $primary-red;
          }

          &:focus-visible {
            border-color: transparent;
            outline: none;
          }

          h3 {
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .pageContainer {
    padding-top: 50px;
    padding-bottom: 100px;
    background-color: $off-white;
    min-height: 500px;
  }
}
