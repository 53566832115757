@import "./_colors.scss";

.files-picker-form-group {
  label,
  .picked-file-name,
  .picker-text {
    text-align: end;
    font-family: InterV;
    font-size: 14px;
    color: $light-black;
    text-transform: uppercase;
    margin-top: 7px;

    @media (max-width: 991.8px) {
      text-align: start;
    }
  }

  .picker-gray-container {
    padding: 15px 18px;
    background-color: $input-bg;
  }

  .picker-text {
    display: flex;
    align-items: center;
    width: 100%;
    color: rgba(15, 25, 35, 0.5);
    text-align: start;
    text-transform: capitalize;
    margin-top: 0;

    .attachment-icon {
      margin-inline-end: 10px;
    }
  }

  .picker-button,
  .icon-button {
    margin-inline-start: 10px;

    i {
      &::before {
        font-weight: bold !important;
        color: $primary-red;
      }
    }
  }
}
