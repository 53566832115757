@import "./_colors.scss";

.static-page {
  .bg {
    width: 100%;
    background-image: url("../assets/img/riotgames-fist-watermark.png"),
      $heading-bg;
    background-position: center, center;
    background-repeat: no-repeat;
    background-size: 41% 140%, cover;

    h1 {
      padding-top: 271px;
      padding-bottom: 96px;
      font-family: InterV;
      font-size: 60px;
      font-weight: 800;
      line-height: 1.25;
      text-align: start;
      color: $off-white;
      text-transform: uppercase;
      font-family: "RiotSans";
    }
  }

  @media (max-width: 991px) {
    .bg {
      h1 {
        font-size: 40px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .bg {
      h1 {
        font-size: 30px;
      }
    }
  }
}
