@import "./_colors.scss";

.login-page {
  background: $off-white;
  padding-top: 285px;

  .formContainer {
    padding-block: 100px;
    background-color: $off-white;

    .heading {
      font-family: InterV;
      font-size: 72px;
      font-weight: 800;
      text-align: center;
      color: $black;
      margin-bottom: 0;
      text-transform: uppercase;

      @media (max-width: 539.8px) {
        font-size: 40px;
      }
    }
    .subHeading {
      font-size: 16px;
      letter-spacing: 0.48px;
      text-align: center;
      line-height: 1.25;
      color: $black;
      margin-bottom: 71px;
      font-family: InterV;

      a {
        font-weight: bold;
        letter-spacing: 0.48px;
        color: $primary-red;
      }
    }

    .row {
      justify-content: center;
    }

    .forgotten-password {
      margin-top: 100px;
      font-size: 16px;
      font-weight: bold;
      color: $primary-red;
      text-align: center;
      font-family: InterV;
    }
  }
}
