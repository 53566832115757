@import "./_colors.scss";
@import "./radioButton.scss";
@import "./selectBox.scss";

@font-face {
  font-family: "Cairo";
  src: url("../assets/fonts/Cairo-Regular.eot");
  src: local("☺"), url("../assets/fonts/Cairo-Regular.woff") format("woff"),
    url("../assets/fonts/Cairo-Regular.ttf") format("truetype"),
    url("../assets/fonts/Cairo-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DINPro-Bold";
  src: url("../assets/fonts/DINPro Bold.eot");
  src: local("☺"), url("../assets/fonts/DINPro Bold.woff") format("woff"),
    url("../assets/fonts/DINPro Bold.ttf") format("truetype"),
    url("../assets/fonts/DINPro Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "InterV";
  src: url("../assets/fonts/InterV.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "RiotSans";
  src: url("../assets/fonts/RiotSans-Bold.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RiotSans";
  src: url("../assets/fonts/RiotSansAR-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

.riot-sans-font {
  font-family: "RiotSans" !important;
}

body {
  font-family: "InterV", "Open Sans", sans-serif;
  color: $light-black;
  background: $off-white;

  &#ar {
    font-family: "Cairo", "Open Sans", sans-serif;
  }
}

a {
  color: $primary-red !important;
  text-decoration: none !important;
}

a:hover {
  color: $primary-red;
  text-decoration: none;
}

.rotate-y-180 {
  transform: rotateY(180deg);
}

.full-width {
  width: 100%;
}

.bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.font-italic {
  font-style: italic;
}

.under-line {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.pre-line-space {
  white-space: pre-line;
}

.custom-button {
  &.btn-primary {
    background-color: $primary-red !important;
    border-color: $primary-red !important;
    height: 45px !important;
    width: 260px !important;
    text-transform: uppercase !important;
    font-family: InterV !important;
    font-size: 16px !important;
    font-weight: bold !important;
    line-height: 0.25 !important;
    color: $off-white !important;

    &:focus {
      box-shadow: none !important;
    }

    &:active {
      box-shadow: none !important;
      background-color: $primary-red !important;
      border-color: $primary-red !important;
    }
  }

  &.outline {
    background-color: $off-white !important;
    border-color: $primary-red !important;
    color: $primary-red !important;

    &:active {
      background-color: $off-white !important;
      border-color: $primary-red !important;
      color: $primary-red !important;
    }
  }

  &.bg-transparent {
    background-color: transparent !important;
  }
}

.input-error {
  font-family: InterV;
  font-size: 12px;
  letter-spacing: -0.3px;
  text-align: start;
  color: $error-red;
  margin-bottom: 0;
  margin-top: 6px;
}

.spinner {
  .spinner-border {
    width: 6rem;
    height: 6rem;
    color: $primary-red !important;
    border-width: 0.4rem !important;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1380px;
  }
}
