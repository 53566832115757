@import "./_colors.scss";

.landing-page {
  background-color: $off-white;
  padding-bottom: 250px;

  .bg {
    width: 100%;
    height: 420px;
    margin-bottom: 85px;
    background-image: $heading-bg;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .image-container {
      position: relative;
      height: 100%;

      .riot-image {
        max-width: 100%;
        margin-top: 70px;
      }
    }
  }

  .riot-small-image {
    display: none;
    img {
      max-width: 100%;
    }
  }

  .title {
    font-family: "InterV";
    font-size: 24px;
    font-weight: bold;

    color: $primary-red;
    text-transform: uppercase;
  }

  hr {
    width: 90%;
    margin-left: 5%;
    margin-block: 0;
    border: 2px solid $primary-red;
    opacity: 1;
  }

  .text {
    font-family: InterV;
    font-size: 48px;
    font-weight: 800;
    line-height: 1.17;
    text-align: center;
    color: $black;
    margin-top: 46px;
    margin-bottom: 42px;
    text-transform: uppercase;
  }

  .action-button {
    width: 261px;
    height: 45px;
    border-radius: 5px;
    padding: 10px;
    color: $off-white;
    border: none;
    background-color: $primary-red;
    text-transform: uppercase;
  }

  P {
    white-space: pre-wrap;
  }

  @media (max-width: 1399.8px) {
    .bg {
      height: 360px;
    }
    .text {
      height: 225px;
    }
  }

  @media (max-width: 1199.8px) {
    .bg {
      height: 308px;
    }
  }

  @media (max-width: 991.8px) {
    .bg {
      height: 255px;
      margin-bottom: 65px;

      .image-container {
        .riot-image {
          margin-top: 80px;
        }
      }
    }

    .text {
      height: auto;
    }
  }

  @media (max-width: 767.8px) {
    .bg {
      background-size: 90% 140%, cover;
      height: 210px;
    }
  }
  @media (max-width: 480px) {
    .bg {
      margin-bottom: 5px;
      height: 180px;

      .image-container {
        display: none;
      }
    }

    .riot-small-image {
      display: block;
    }

    .text {
      font-size: 30px;
    }
  }
}
