$off-white: #f6f6f7;
$text-white: #f1f5f1;
$heading-bg: linear-gradient(to top, #141e37, #0a1428);
$black: #050505;
$footer-bg: #110f1a;
$light-black: #0f1923;
$border-black: #080426;
$input-bg: #ebebeb;
$gray: rgba(8, 4, 37, 0.8);
$light-gray: rgba(15, 25, 35, 0.5);
$gray-ash: rgba(40, 58, 90, 0.9);
$text-gray: rgba(246, 246, 247, 0.6);
$error-red: #ff4655;
$primary-red: #eb0029;
$lol-bg: #c8aa6e;
$wild-rift-bg: #32c8ff;
$transparent-black: rgba(0, 0, 0, 0.5);
$transparent-gray: rgba(5, 5, 5, 0.6);
$secondary-gray: #717171;
$success-green: rgba(#1a9f07, 0.7);
$cyan: #0bbfbc;
