@import "./_colors.scss";

.custom-pagination {
  .page-item {
    .page-link {
      padding: 10px 18px;
      color: $black;
      font-family: InterV;
      font-weight: bold;
    }

    &.active {
      .page-link {
        background-color: $primary-red;
        border-color: $primary-red;
        color: $off-white !important;
      }
    }
  }
}
