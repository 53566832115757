@import "../_colors.scss";

.home-page {
  min-height: 100vh;
  padding-bottom: 100px;

  .bg {
    width: 100%;
    background-position: center, center;
    background-repeat: no-repeat;

    &.valorant {
      background-image: url("../../assets/img/valorant-watermark.png"),
        $heading-bg;
      background-size: 80% 140%, cover;
    }

    &.lol {
      background-image: url("../../assets/img/lol-watermark.png"), $heading-bg;
      background-size: 45% 100%, cover;
    }

    &.wr {
      background-image: url("../../assets/img/wr-watermark.png"), $heading-bg;
      background-size: 35% 100%, cover;
    }

    .heading {
      padding-top: 241px;
      max-width: 800px;
      padding-bottom: 31px;
      font-size: 60px;
      font-weight: 800;
      line-height: 1.25;
      text-align: start;
      color: $off-white;
      text-transform: uppercase;
      font-family: "RiotSans";

      @media (max-width: 539.8px) {
        white-space: normal;
      }
    }

    .subtext {
      font-size: 24px;
      text-align: start;
      color: $text-gray;
      margin-bottom: 51px;
    }

    .applyHere {
      font-size: 16px;
      font-weight: bold;
      color: $primary-red;
      margin-bottom: 88px;
      text-transform: uppercase;
      cursor: pointer;
    }

    .game-banner-container {
      position: relative;

      &.ar {
        margin-right: 50%;
      }

      .game-banner {
        max-width: 100%;
        position: absolute;
        right: 0;
        bottom: -60px;

        &.to-game-banner {
          bottom: -70px;
        }

        &.ar {
          right: unset;
          left: 0;
        }
      }
    }
  }

  .ongoing-count-container {
    color: $black;
    font-weight: 800;
    margin-top: 24px;
    align-items: baseline;

    &.inHeader {
      margin-bottom: 43px;
    }

    .cup-icon {
      height: 31px;
    }

    .ongoing-count {
      font-size: 32px;

      &.red {
        color: $primary-red;
      }
    }

    .ongoing-text {
      font-size: 16px;

      &.offWhite {
        color: $off-white;
      }
    }
  }

  .ongoing-list {
    .heading-text {
      font-size: 48px;
      font-weight: bold;
    }
  }

  @media (max-width: 1400px) {
    .bg {
      &.lol {
        background-size: 55% 100%, cover;
      }
    }
  }

  @media (max-width: 1200px) {
    .bg {
      &.lol {
        background-size: 65% 100%, cover;
      }
    }
  }

  @media (max-width: 991px) {
    .bg {
      padding-bottom: 88px;

      &.lol {
        background-size: 80% 140%, cover;
      }

      &.wr {
        background-size: 70% 100%, cover;
      }

      .heading {
        font-size: 40px;
      }

      .applyHere {
        margin-bottom: 30px;
      }

      .game-banner-container {
        .game-banner {
          position: static;
        }
      }
    }

    .ongoing-list {
      .heading-text {
        font-size: 34px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .bg {
      .heading {
        font-size: 30px;
      }
    }

    .ongoing-list {
      .heading-text {
        font-size: 26px;
      }
    }
  }
}

#ar {
  .home-page {
    .heading {
      max-width: unset;
    }
  }
}
