@import "./_colors.scss";

.date-picker-form-group {
  &.top-label-container {
    flex-direction: column;

    .top-label {
      text-align: start;
    }
  }

  label {
    text-align: end;
    font-family: InterV;
    font-size: 14px;
    color: $light-black;
    text-transform: uppercase;
    margin-top: 7px;

    @media (max-width: 991.8px) {
      text-align: start;
    }
  }

  .rmdp-container {
    width: 100%;

    input {
      padding: 15px 22px;
      height: 56px;
      width: 100%;
      background-color: $input-bg;
      border: unset;
      border-radius: 2px;
      font-family: InterV;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: -0.35px;
      text-align: left;
      color: $light-black;

      &.validation-error {
        padding: 15px 22px;
        border: 2px solid $error-red;
      }

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        opacity: 1; /* Firefox */
        color: $light-gray;
        font-family: InterV;
        font-weight: normal;
      }

      &:-ms-input-placeholder {
        color: $light-gray;
        font-family: InterV;
        font-weight: normal;
      }

      &::-ms-input-placeholder {
        color: $light-gray;
        font-family: InterV;
        font-weight: normal;
      }
    }
  }
}
