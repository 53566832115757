@import "./_colors.scss";

.register-page {
  .header {
    background-image: $heading-bg;

    .text-container {
      h1 {
        font-family: InterV;
        font-size: 72px;
        font-weight: 800;
        text-align: start;
        color: $off-white;
        padding-top: 271px;
        padding-bottom: 31px;
        margin-bottom: 0;
        text-transform: uppercase;
        font-family: "RiotSans";

        @media (max-width: 539.8px) {
          font-size: 40px;
        }
      }

      .subtext {
        font-size: 24px;
        text-align: start;
        color: $text-gray;
        padding-bottom: 70px;
      }
    }
  }

  .formContainer {
    padding-block: 100px;
    background-color: $off-white;

    .heading {
      font-family: InterV;
      font-size: 48px;
      font-weight: bold;
      text-align: start;
      color: $black;
      padding-bottom: 28px;
      margin-bottom: 0;
      text-transform: uppercase;

      @media (max-width: 539.8px) {
        font-size: 24px;
      }
    }
  }
}
