@import "../_colors.scss";

.profile-tournament-details {
  .header {
    background-image: $heading-bg;
    padding-top: 271px;
    padding-bottom: 99px;

    .text-container {
      h1 {
        font-size: 72px;
        font-weight: 800;
        text-align: start;
        color: $off-white;
        margin-bottom: 0;
        text-transform: uppercase;
        font-family: "RiotSans";

        @media (max-width: 539.8px) {
          font-size: 40px;
        }
      }
    }
  }

  .pageContainer {
    padding-top: 50px;
    padding-bottom: 100px;
    background-color: $off-white;
    min-height: 500px;

    .detail-row {
      margin-bottom: 10px;
      text-align: start;
      color: $black;
      font-weight: bold;
      background: $input-bg;

      .name {
        font-size: 24px;
      }

      .step-number {
        font-size: 12px;
      }

      .step {
        font-size: 16px;
      }

      .date {
        font-size: 14px;
        font-weight: normal;
      }

      .status {
        text-align: end;
        text-transform: uppercase;
        color: $primary-red;

        &.submit {
          cursor: pointer;

          .status-name {
            color: $primary-red;
          }
        }

        .edit-submission {
          display: inline-block;
          font-size: 12px;
          padding-inline-end: 20px;
          cursor: pointer;
        }

        .status-name {
          display: inline-block;
          font-size: 16px;
          padding-inline: 7px 11px;
          color: $black;
        }
      }

      .action-note-container {
        margin-block: 24px;
        padding-block: 20px;
        border-block: 1px solid $border-black;

        .note {
          font-size: 14px;
          line-height: 1.71;
          color: $transparent-gray;
          word-break: break-all;
        }

        .note-by {
          font-size: 12px;
          font-style: italic;
          text-align: start;
          color: $primary-red;
        }
      }

      .edit-rejection {
        font-size: 16px;
        padding-inline-end: 20px;
        text-align: end;
        text-transform: uppercase;
        color: $primary-red;
        cursor: pointer;
      }

      .bi-arrow-right::before {
        font-weight: bold !important;
      }

      @media (max-width: 991.98px) {
        .step-number {
          margin-top: 5px;
        }

        .step,
        .date,
        .status {
          margin-top: 7px;
        }
        .status {
          text-align: start;
        }
      }
    }
  }

  .approved-congratulations {
    font-size: 28px;
    font-weight: bold;
  }
}
