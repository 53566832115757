.custom-radio-button {
  label {
    text-align: start;
    font-family: InterV;
    font-size: 14px;
    color: $light-black;

    .label-description {
      font-weight: normal;
    }

    @media (max-width: 991.8px) {
      text-align: start;
    }
  }

  .form-check-input {
    &:checked {
      background-color: $primary-red;
      border-color: $primary-red;
    }
    &:focus {
      border-color: $primary-red;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba($primary-red, 25%);
    }
  }
}
