@import "../_colors.scss";

.home-page {
  .our-support {
    .section-title {
      font-size: 48px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .subtitle-container {
      padding-left: 15px;
      padding-bottom: 15px;
      border-left: 2px solid $primary-red;
      margin-top: 28px;
      margin-bottom: 75px;

      .heading {
        font-size: 24px;
        font-weight: bold;
        color: $primary-red;
        text-transform: uppercase;
        text-align: left;
      }

      .subheading {
        font-size: 14px;
        color: $transparent-gray;
        margin-bottom: 0;
        margin-top: 25px;
        text-align: left;
      }
    }

    .support-card {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      position: relative;
      transition: all 1s ease-in-out;
      background-color: #c9cbd1;
      padding: 24px;

      .support-icon {
        max-width: 30px;
        max-height: 30px;
        margin-bottom: 16px;
      }

      .support-title {
        font-size: 16px;
        font-weight: bold;
        color: $black;
        text-transform: uppercase;
        text-align: center;
      }

      .support-index {
        position: absolute;
        top: 24px;
        left: 31px;
        color: $black;
        font-size: 12px;
        font-weight: bold;

        .current-index {
          color: $primary-red;
        }
      }
    }
  }
}
