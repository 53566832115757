@import "../_colors.scss";

.on-going-tournaments {
  min-height: 100vh;

  .bg {
    width: 100%;
    background-position: center, center;
    background-repeat: no-repeat;

    &.valorant {
      background-image: url("../../assets/img/valorant-watermark.png"),
        $heading-bg;
      background-size: 45% 140%, cover;
    }

    &.lol {
      background-image: url("../../assets/img/lol-watermark.png"), $heading-bg;
      background-size: 30% 100%, cover;
    }

    &.wr {
      background-image: url("../../assets/img/wr-watermark.png"), $heading-bg;
      background-size: 30% 100%, cover;
    }

    h1 {
      padding-top: 271px;
      padding-bottom: 31px;
      font-size: 60px;
      font-weight: 800;
      line-height: 1.25;
      text-align: start;
      color: $off-white;
      text-transform: uppercase;
      font-family: "RiotSans";
    }

    .subtext {
      font-size: 24px;
      text-align: start;
      color: $text-gray;
      padding-bottom: 70px;
    }
  }

  .input-form-group {
    .field-form-label {
      font-size: 12px;
      color: $light-black;
    }
  }

  @media (max-width: 1400px) {
    .bg {
      &.valorant {
        background-size: 60% 140%, cover;
      }

      &.lol {
        background-size: 40% 100%, cover;
      }
    }
  }

  @media (max-width: 1024px) {
    .bg {
      &.valorant {
        background-size: 60% 140%, cover;
      }

      &.lol {
        background-size: 47% 100%, cover;
      }
    }
  }

  @media (max-width: 991px) {
    .bg {
      h1 {
        font-size: 40px;
      }

      &.valorant {
        background-size: 70% 140%, cover;
      }

      &.lol {
        background-size: 55% 100%, cover;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .bg {
      h1 {
        font-size: 30px;
      }

      &.valorant,
      &.lol {
        background-size: 80% 140%, cover;
      }

      &.wr {
        background-size: 60% 100%, cover;
      }
    }
  }
}
