@import "../_colors.scss";

.profile-tournaments {
  .pageContainer {
    .heading {
      font-size: 48px;
      font-weight: bold;
      text-align: start;
      color: $black;
      padding-bottom: 50px;
      margin-bottom: 0;
      text-transform: uppercase;

      @media (max-width: 539.8px) {
        font-size: 24px;
      }
    }

    tr {
      border-bottom: solid $off-white 10px;
      text-align: start;
      color: $black;
      font-weight: bold;
      cursor: pointer;
      background: $input-bg;
      --bs-table-accent-bg: $input-bg;

      .name {
        font-size: 24px;
      }

      .step {
        font-size: 16px;
      }

      .date {
        font-size: 16px;
        font-weight: normal;
      }

      .status {
        text-align: end;

        &.submit {
          color: $primary-red;
        }

        .status-text {
          display: inline-block;
          font-size: 16px;
          padding: 7px 11px;
          text-transform: uppercase;
        }

        .bi-arrow-right::before {
          font-weight: bold !important;
        }
      }
    }

    @media (max-width: 991.98px) {
      table {
        tbody,
        tr,
        td {
          display: block;
        }

        .status {
          text-align: start;
        }
      }
    }
  }
}
