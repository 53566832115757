@import "./colors.scss";

.player-multi-games-page {
  background-color: $off-white;
  min-height: 800px;

  .bg {
    width: 100%;
    background-image: $heading-bg;

    h1 {
      margin: 176px 0 16px;
      font-size: 80px;
      font-weight: 800;
      line-height: 1.22;
      text-align: start;
      color: $off-white;
      white-space: pre-wrap;
      text-transform: uppercase;
      font-family: "RiotSans";
    }

    p {
      font-size: 18px;
      text-align: start;
      color: $text-gray;
    }

    .games-section {
      margin-top: 100px;
      padding-bottom: 98px;

      .game {
        width: 100%;
        height: 437px;
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        transition: all 1s ease-in-out;

        &.valorant {
          background-image: linear-gradient(
            to top,
            rgb(255, 70, 85),
            rgb(255, 70, 85),
            rgb(255, 70, 85),
            rgb(255, 70, 85),
            rgba(255, 70, 85, 0.85),
            rgba(239, 59, 96, 0.89),
            rgba(221, 52, 105, 0.82),
            rgba(201, 49, 111, 0.76),
            rgb(151 49 116 / 65%),
            rgb(99 49 107 / 32%),
            rgb(53 42 85 / 0%),
            rgb(20 30 55 / 0%)
          );

          &:hover {
            background-color: $error-red;
          }
        }

        &.lol {
          background-image: linear-gradient(
            to top,
            #c8aa6e,
            #c8aa6e,
            #c8aa6e,
            #c8aa6e,
            #c8aa6ec9,
            #c59868eb,
            #be8764db,
            #b57762db,
            #9758608a,
            #6f415a1a,
            #422f4d00,
            #141e3700
          );

          &:hover {
            background-color: $lol-bg;
          }
        }

        &.wild-rift {
          background-image: linear-gradient(
            to top,
            #32c8ff,
            #32c8ff,
            #32c8ff,
            #32c8ff,
            #32c8ffd9,
            #35b8eec2,
            #36a8ddcc,
            #3798cc94,
            #3476a69c,
            #2d567f94,
            #22395a57,
            #141e3700
          );

          &:hover {
            background-color: $wild-rift-bg;
          }
        }

        .game-logo {
          width: 82%;
          box-sizing: border-box;
          position: absolute;
          bottom: 23px;
          padding-bottom: 13px;
          margin: 0 45px 0 40px;
          object-fit: contain;
          border-bottom: 2px solid $off-white;
          z-index: 1;

          .logo {
            object-fit: contain;
          }

          .arrow {
            width: 18px;
            height: 20px;
            object-fit: contain;
            margin-top: 50px;
          }

          &.lol-game-logo {
            .arrow {
              margin-top: 20px;
            }
          }
        }

        .avatar {
          object-fit: none;
          width: 100%;
          height: 497px;
          position: relative;
          top: -60px;

          &.lol-avatar {
            object-fit: cover;
          }
        }
      }
    }

    @media (max-width: 1399px) {
      .bg {
        h1 {
          font-size: 60px;
        }
      }

      .games-section {
        .game {
          margin-top: 75px;
        }
      }
    }
  }

  @media (max-width: 991.98px) {
    .bg {
      h1 {
        font-size: 50px;
      }
    }
  }

  @media (max-width: 490px) {
    .bg {
      h1 {
        font-size: 30px;
      }
    }
  }
}
