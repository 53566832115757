@import "../_colors.scss";

.tournament-card {
  min-height: 300px;
  border-radius: 5px;
  margin-bottom: 20px;
  background-size: 100% 100% !important;
  position: relative;
  overflow: hidden;

  &__content-container {
    background-color: rgba($black, 0.3);
    min-height: 300px;
    width: 100%;
    position: relative;
    border-radius: 5px;
    padding: 30px;
  }

  &__region-and-type {
    position: absolute;
    left: 30px;
    right: 30px;
    top: 30px;
    text-align: start;
    font-weight: bold;
    font-size: 12px;
    color: $off-white;

    .countries {
      padding: 10px;
      background-color: $primary-red;

      &-other {
        cursor: pointer;
      }
    }

    .countries-popover {
      white-space: pre-line;
    }

    .popover-body {
      white-space: pre-line;
    }

    .format_type {
      padding: 10px;
      background-color: rgba($off-white, 0.2);
    }
  }

  &__name-and-dates {
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 30px;
    text-align: start;
    font-weight: bold;
    transform: translateY(75px);
    transition: transform 0.5s ease-in-out;

    .name {
      font-size: 24px;
      color: $off-white;
      margin-bottom: 30px;
    }

    .left-days {
      font-size: 14px;
      color: $text-gray;
    }

    .visit-registration-website {
      margin-top: 30px;
    }
  }

  &.first-card {
    min-height: 620px;

    .tournament-card__content-container {
      min-height: 620px;
    }
  }

  &:hover {
    .tournament-card__name-and-dates {
      transform: translateY(0);
    }
  }
}
