@import "./colors.scss";

.multi-games-page {
  background-color: $off-white;
  min-height: 800px;

  .bg {
    width: 100%;
    background-image: $heading-bg;

    h1 {
      margin: 176px 0 16px;
      font-family: "RiotSans";
      font-size: 80px;
      font-weight: 800;
      line-height: 1.22;
      text-align: start;
      color: $off-white;
      white-space: pre-wrap;

      .game-name {
        color: $primary-red !important;
      }
    }

    p {
      font-size: 18px;
      text-align: start;
      color: $text-gray;
    }
  }
  .games-section {
    margin-top: 100px;
    padding-bottom: 98px;

    .game {
      width: 100%;
      height: 437px;
      border-radius: 5px;
      position: relative;
      cursor: pointer;
      transition: all 1s ease-in-out;

      .avatar {
        object-fit: none;
        width: 100%;
        height: 497px;
        position: relative;
        top: -57px;

        &.lol-avatar {
          object-fit: cover;
        }
      }

      .game-logo {
        box-sizing: border-box;
        position: absolute;
        width: 117px;
        height: 77px;
        top: 37px;
        left: 37px;
        object-fit: contain;
        z-index: 100;
      }

      &.valorant {
        background-image: linear-gradient(
          to top,
          rgb(255, 70, 85),
          rgb(255, 70, 85),
          rgb(255, 70, 85),
          rgb(255, 70, 85),
          rgba(255, 70, 85, 0.85),
          rgba(239, 59, 96, 0.89),
          rgba(221, 52, 105, 0.82),
          rgba(201, 49, 111, 0.76),
          rgb(151 49 116 / 65%),
          rgb(99 49 107 / 32%),
          rgb(53 42 85 / 0%),
          rgb(20 30 55 / 0%)
        );
        cursor: pointer;
        transition: all 1s ease-in-out;

        &:hover {
          background-color: $error-red;
        }
      }

      &.lol {
        background-image: linear-gradient(
          to top,
          #c8aa6e,
          #c8aa6e,
          #c8aa6e,
          #c8aa6e,
          #c8aa6ec9,
          #c59868eb,
          #be8764db,
          #b57762db,
          #9758608a,
          #6f415a1a,
          #422f4d00,
          #141e3700
        );
        cursor: pointer;
        transition: all 1s ease-in-out;

        &:hover {
          background-color: $lol-bg;
        }
      }

      &.wild-rift {
        background-image: linear-gradient(
          to top,
          #32c8ff,
          #32c8ff,
          #32c8ff,
          #32c8ff,
          #32c8ffd9,
          #35b8eec2,
          #36a8ddcc,
          #3798cc94,
          #3476a69c,
          #2d567f94,
          #22395a57,
          #141e3700
        );
        cursor: pointer;
        transition: all 1s ease-in-out;

        &:hover {
          background-color: $wild-rift-bg;
        }
      }

      .arrow-container {
        width: 82%;
        position: absolute;
        bottom: 23px;
        padding-bottom: 13px;
        margin: 0 45px;
        border-bottom: 2px solid $off-white;
        display: flex;
        justify-content: flex-end;

        .arrow {
          width: 18px;
          height: 20px;
          object-fit: contain;
        }
      }
    }
  }

  @media (max-width: 1399px) {
    .bg {
      h1 {
        font-size: 60px;
      }
    }

    .games-section {
      .game {
        margin-top: 55px;
      }
    }
  }

  @media (max-width: 990px) {
    .bg {
      h1 {
        font-size: 50px;
      }
    }
  }

  @media (max-width: 490px) {
    .bg {
      h1 {
        font-size: 30px;
      }
    }
  }
}
