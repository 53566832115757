@import "./_colors.scss";

.custom-tournament-create-pages {
  .header {
    background-image: $heading-bg;

    .text-container {
      h1 {
        font-size: 72px;
        font-weight: 800;
        text-align: start;
        color: $off-white;
        padding-top: 271px;
        padding-bottom: 31px;
        margin-bottom: 0;
        text-transform: uppercase;
        font-family: "RiotSans";

        @media (max-width: 539.8px) {
          font-size: 40px;
        }
      }

      .subtext {
        font-size: 24px;
        text-align: start;
        color: $text-gray;
        padding-bottom: 70px;
      }
    }
  }

  .formContainer {
    padding-block: 100px;
    background-color: $off-white;

    .regions-countries {
      opacity: 0.6;
      font-family: InterV;
      font-size: 12px;
      line-height: 1.67;
      text-align: start;
      color: $secondary-gray;
      white-space: pre;
    }

    .heading {
      font-size: 48px;
      font-weight: bold;
      text-align: start;
      color: $black;
      padding-bottom: 28px;
      margin-bottom: 0;
      text-transform: uppercase;

      @media (max-width: 539.8px) {
        font-size: 24px;
      }
    }

    .subheading {
      font-size: 36px;
      font-weight: bold;
      text-align: start;
      color: $black;
      margin-bottom: 0;
      text-transform: uppercase;

      @media (max-width: 539.8px) {
        font-size: 20px;
      }
    }

    .form_note {
      font-size: 12px;
      text-align: start;
      color: $transparent-gray;
    }

    .field-label {
      text-align: end;
      font-size: 14px;
      color: $light-black;
      text-transform: uppercase;
      margin-top: 17px;
      white-space: pre-line;
      font-weight: bold;

      @media (max-width: 991.8px) {
        text-align: start;
      }
    }

    .field-sub-label {
      margin-top: 0;
      font-weight: normal;
      text-transform: none;
    }

    .form-field-container {
      padding: 15px 20px;
      border-radius: 2px;
      background-color: $input-bg;

      .mena-checkbox-container {
        border-bottom: solid 2px $border-black;

        .mena-note {
          font-size: 14px;
          line-height: 2.29;
          letter-spacing: -0.35px;
          text-align: left;
          color: $light-black;
        }
      }
    }

    .field-note {
      font-size: 12px;
      line-height: 1.4;
      text-align: start;
      color: $light-black;
    }

    .broadcast_information_note {
      opacity: 0.6;
      font-size: 12px;
      line-height: 1.67;
      text-align: start;
      color: $secondary-gray;
    }

    .our-support-page-word {
      color: $primary-red;
    }
  }
}
